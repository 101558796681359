import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Üdvözöljük a Perk Haven Caféban
			</title>
			<meta name={"description"} content={"A StreetPub nem csak egy kocsma - ez egy menedékhely a bajtársiasság és a kényelem szívében. "} />
			<meta property={"og:title"} content={"Home | Üdvözöljük a Perk Haven Caféban"} />
			<meta property={"og:description"} content={"A StreetPub nem csak egy kocsma - ez egy menedékhely a bajtársiasság és a kényelem szívében. "} />
			<meta property={"og:image"} content={"https://vorides.live/img/image-1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vorides.live/img/135275.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vorides.live/img/135275.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vorides.live/img/135275.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vorides.live/img/135275.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vorides.live/img/135275.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vorides.live/img/135275.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://vorides.live/img/image-1.jpg) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Ahol minden csésze egy mese az ízletes élvezetekről
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
							Üdvözöljük a Perk Haven Caféban
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="196px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					Kik vagyunk
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="20%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			/>
			<Box
				width="85%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
					Lépjen be egy olyan világba, ahol a frissen őrölt kávébabok illata és a barátságos csevegés megnyugtató zümmögése a kávérajongók paradicsomát teremti meg. A Perk Haven Café meghívja Önt, hogy ne csak egy csésze kávét, hanem egy élményt élvezzen, ahol minden korty egy utazás a gazdag ízvilágon és a hangulatos sarkunk hívogató környezetén keresztül.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box padding="20px 72px 20px 72px" border-radius="24px" margin="0px 0px 64px 0px" sm-padding="60px 36px 60px 36px">
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Miért válassza a Perk Haven Café-t?
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Tökéletességig kidolgozva
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Minden csészét aprólékosan tökéletesítenek, hogy ízlelőbimbóinak kellemes élményt nyújtsanak.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Összehasonlíthatatlan hangulat
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						A lágy jazz dallamok, a kényelmes ülőalkalmatosságok és a kávégépek halk zümmögése tökéletes környezetet teremt a pihenéshez vagy a beszélgetéshez.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Helyben termelt finomságok
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Kényeztesse magát különféle, helyben beszerzett finomságokkal, amelyek kiegészítik a kávéélményt a közösségre jellemző ízekkel.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						4
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Kávé lelkiismerettel
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Büszkék vagyunk a helyi gazdák támogatására és az etikus beszerzés biztosítására, így az Ön csésze kávéja nemcsak finom, hanem társadalmilag is felelős.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text margin="12px 0" font="normal 900 42px/1.2 --fontFamily-sans">
							Perk Haven Café - Ahol minden csésze egy mese az ízletes élvezetekről
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://vorides.live/img/image-2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://vorides.live/img/image-3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://vorides.live/img/image-4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});